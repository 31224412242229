import React,{useState,useEffect}from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import Meta from "@components/meta"
import BottomCta from "@components/bottom-cta"
import '@styles/pages/about.scss'
import MissionTxt from "@images/svg/icon-mission_pc.svg"
import Akira from "@images/img/profile/img-fukumoto_akira_pc.png"
import Kuramoto from "@images/img/profile/profile-koki_kuramoto_pc.png"

import posed, { PoseGroup } from 'react-pose'
const transition = {
  visible: { opacity: 1,delay: 600, },
  hidden: { opacity: 0,delay: 600}
}
const Box = posed.div(transition)


const ContactPage = props => {
  const [path, setPath] = useState(false);

  useEffect(()=> {
    setPath(true);
    return ()=> setPath(false);
  })
  return (
      <Layout>
        <Meta
          title="私たちについて"
          desc="KnockLearnの私たちについてのページです。"
        />
        <PoseGroup>
          <Box key={"about"} pose={path ? "visible" : "hidden"}>
          <section class="page">
            <div class="page-top">
              <div class="page-top__container">
                <ol>
                  <li><Link to="/">TOP&nbsp;>&nbsp;</Link></li>
                  <li>私たちについて</li>
                </ol>
                <h2 class="page-top__heading">ABOUT US</h2>
                <p class="page-top__lead">私たちについて</p>
              </div>
            </div>
            <div class="section">
              <div class="section__container">
                <div class="about-mission">
                  <div class="headingBg1">
                    <MissionTxt />
                  </div>
                  <div>
                    <p class="txt-lh_300">
                      終身雇用の終焉・目まぐるしく変化する社会・不確実な未来。<br/>
                      このような世の中において、これまで以上に自ら道を切り開くことが求められる。<br/>
                      しかし、現在の学校教育だけではこの力を養うことは難しい。<br/>
                      足りていないのは”社会との接点”であり、多くの学校がこの接点を創出できていない。<br/>
                      <br class="tb_none"/><br class=""/>
                      お子様の未来は社会と接点を持ち、実現可能性を感じられるかどうかで大きく変わる。
                    </p>
                    <p class="txt-lh_300">
                      私たちは社会との接点を創出し、<br class="tb_none"/>
                      好奇心に従って、主体的に考え、行動するお子様の成長に寄り添い、<br class="tb_none"/>
                      明るい日本の未来に貢献します。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <section class="section member">
              <div class="section__container">
                <p class="heading2-lead">MEMBER</p>
                <h2 class="heading2">創業メンバー</h2>
                <div class="profile-list">
                  <div class="profile-card">
                      <img class="profile-card__image" src={Akira} alt="福本 英" />
                      <p class="profile-card__name">福本 英</p>
                      <p class="profile-card__about">CEO</p>
                      <p class="profile-card__desc txt-lh_175">
                        兵庫県姫路市出身。<br/>
                        2016年に九州大学経済学部経済工学科に入学。<br/>
                        2018年より1年間University of Leedsビジネススクールに留学。<br/>
                        在学中にSlogan Inc.の新規事業や、REAPRA Ventures(VC) 福岡オフィス立ち上げなど、計6社の事業立ち上げに従事。<br/>
                        2020年にKnockLearnを創業。<br/>
                        座右の銘：「笑う門には福来る」
                      </p>
                  </div>
                  <div class="profile-card">
                      <img class="profile-card__image" src={Kuramoto} alt="倉本 光希" />
                      <p class="profile-card__name">倉本 光希</p>
                      <p class="profile-card__about">COO</p>
                      <p class="profile-card__desc txt-lh_175">
                        福岡県出身。2016年に西南学院大学文学部英語専攻に入学。4年間大手個別指導塾にて講師経験あり。生徒指導のみではなく、教室運営なども行い社内受賞歴あり。進路アドバイザー検定合格。現在は大手旅行会社にて教育営業を行う。学生時代にスタートアップ企業にて広報・人事責任者の経験あり。<br/>
                        座右の銘：「置かれた場所で咲きなさい」
                      </p>
                  </div>
                </div>
              </div>
            </section>
            
          </section>
          <BottomCta/>
          </Box>
        </PoseGroup>
      </Layout>
  )
}
export default ContactPage
